import "../assets/Styles/Section6.css"
import Temp1 from "../assets/Images/temp-1.jpg"
import Temp2 from "../assets/Images/temp-2.png"
import Temp3 from "../assets/Images/temp-3.png"
const Section6 = () => {
    return (
        <>
            <div className="app">
                <div className="content">
                    <div className="text-section">
                        <h4>START BUILDING YOUR CAREER</h4>
                        <h1>Professional resumes for effective job interviews</h1>
                        <p>
                            A great job application leads to a good interview. An amazing resume is what makes it all possible. Start off strong with the hiring manager by creating a positive professional image. A job interview can be much easier if they have a favorable view of your resume and cover letter.
                        </p>
                        <div className="buttons">
                            <button className="primary-button">Get started now</button>
                            <button className="secondary-button">Resume examples</button>
                        </div>
                    </div>
                    <div className="resume-section">
                        <img src={Temp1} alt="Resume 1" className="resume resume-1" />
                        <img src={Temp3} alt="Resume 2" className="resume resume-2" />
                        <img src={Temp2} alt="Resume 3" className="resume resume-3" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Section6;