import "../assets/Styles/Section7.css"
const Section7 = () => {
    return (
        <div className="join-users-container">
            <h1 className="headline">
                Join over <span className="highlighted-text">40,319,000</span>
                <br /> users worldwide
            </h1>
            <p className="subtext">Start for free — try our resume builder now</p>
            <button className="cta-button">Create my resume</button>
        </div>
    )
}
export default Section7;