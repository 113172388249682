
import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouterNavigate from './RouterNavigate';
import "./assets/Styles/Main.css";

 
function App() {

  return (<>
    <Router>
      <RouterNavigate/>
    </Router>
  </>
  )
}

export default App;

