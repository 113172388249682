import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css'; // Main Swiper styles
import 'swiper/css'; // Base Swiper styles
import 'swiper/css/navigation'; // Navigation module styles
import 'swiper/css/pagination'; // Pagination module styles


// Import Swiper styles
import { Navigation, Pagination } from 'swiper/modules';
import "../assets/Styles/Section2.css"

const Section2 = () => {
    const reviews = [
        {
            rating: 1,
            text: 'Great website, a bit pricy but interface and options are very rich!',
            name: 'Paul',
            time: 'about 5 hours ago'
        },
        {
            rating: 5,
            text: 'It is amazing, detail creating CV and amazing sales catching cover letter.',
            name: 'Farhad Keihan',
            time: 'about 16 hours ago'
        },
        {
            rating: 3,
            text: 'Always helpful when I need to update my resume for career opportunities.',
            name: 'Chynii Dreamer',
            time: '1 day ago'
        },
        {
            rating: 2,
            text: 'Great website, a bit pricy but interface and options are very rich!',
            name: 'Paul',
            time: 'about 5 hours ago'
        },
    ];

    return (
        <>
            <div className="reviews-section">
                <h2>Reviewed by the community. Trusted <br />
                    by professionals</h2>
                {/* <div className="overall-rating">
                <div className="rating-stars">
                    <span className="stars">★★★★★</span>
                    <span className="rating-value">4.5 out of 5</span>
                </div>
                <div className="trustpilot">
                    <img src="/path/to/trustpilot-logo.png" alt="Trustpilot" />
                    <p>based on 51,593 reviews</p>
                </div>
            </div> */}

                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={50}
                    slidesPerView={4}
                    navigation
                    pagination={{ clickable: true }}
                    className="reviews-swiper mt-4"
                >
                    {reviews.map((review, index) => (
                        <SwiperSlide key={index} className="review-slide">
                            <div className="review-card">
                                <div className="stars">{'★'.repeat(review.rating)}</div>
                                <p>{review.text}</p>
                                <div className="reviewer-details">
                                    <span className="reviewer-name">{review.name}</span>
                                    <span className="review-time">{review.time}</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <hr />
        </>
    );
};

export default Section2;
