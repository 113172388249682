import { templates } from "../Data/templates";
import { SELECTTEMPLATE } from "../Redux/Actions";
import DarkEffect from "../Components/DarkEffect";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { Button, Stack } from "@mui/material";


const mapStateToProps = (state) => ({
    selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id))
});


const TemplateCard = (props) => {

    const navigate = useNavigate();

    const navigateToFill = (id) => {
        props.setSelectedTemplateId(id);
        navigate("/template/FillDetails")
    }

    return (
        <>
            <div className="Home">
                <div className="home-templates-cont">
                    <h3 className="template-header-title">TEMPLATES</h3>
                    <p className="template-select-text">Select a template to get started</p>

                    <Stack
                        sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: {
                                sm: "1fr 1fr ",
                                md: "1fr 1fr ",
                                lg: "1fr 1fr 1fr",
                                xl: "1fr 1fr 1fr 1fr",
                            },
                            gridGap: "30px",
                        }}>

                        {templates.map((template) => {
                            return (
                                <div key={template.id} id="template" className="templates-img-cont">
                                    <img
                                        className="template-img"
                                        src={template.template_img}
                                        alt={template.template_name}
                                    />
                                    <DarkEffect />
                                    <Button
                                        className="use-template-btn"
                                        onClick={() => navigateToFill(template.id)}
                                        size="medium"
                                        variant="contained">
                                        Use Template
                                    </Button>
                                </div>
                            );
                        })}
                    </Stack>
                </div >
            </div >
        </>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(TemplateCard) 