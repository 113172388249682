import { useState } from "react";
import { Link } from "react-router-dom";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    username: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.fullname) {
      formErrors.fullname = "Full name is required";
    }

    if (!formData.username) {
      formErrors.username = "Username is required";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
    }

    if (!formData.confirm_password) {
      formErrors.confirm_password = "Confirm password is required";
    } else if (formData.password !== formData.confirm_password) {
      formErrors.confirm_password = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log("Form submitted successfully:", formData);
    }
  };

  return (
    <>
      <div className="right-login-form">
        <h3>Create your Account</h3>
        <p>To create an account please fill the details.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-outline mb-3">
            <input
              type="text"
              className="form-control"
              name="fullname"
              placeholder="Full Name"
              value={formData?.fullname}
              onChange={handleChange}
            />
            {errors.fullname && (
              <p className="text-danger">{errors.fullname}</p>
            )}
          </div>

          <div className="form-outline mb-3">
            <input
              type="text"
              className="form-control"
              name="username"
              placeholder="Username"
              value={formData?.username}
              onChange={handleChange}
            />
            {errors.username && (
              <p className="text-danger">{errors.username}</p>
            )}
          </div>

          <div className="form-outline mb-3">
            {/* <label className="form-label" for="form2Example11">Email Address</label> */}
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
              value={formData?.email}
              onChange={handleChange}
            />

            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>

          <div className="form-outline mb-3">
            {/* <label className="form-label" >Password</label> */}
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="form-control"
              value={formData?.password}
              onChange={handleChange}
            />
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>

          <div className="form-outline mb-3">
            {/* <label className="form-label" >Password</label> */}
            <input
              type="password"
              name="confirm_password"
              placeholder="confirm_password"
              className="form-control"
              value={formData?.confirm_password}
              onChange={handleChange}
            />
            {errors.confirm_password && (
              <p className="text-danger">{errors.confirm_password}</p>
            )}
          </div>

          <div className="d-flex flex-column text-center pt-1 mb-5 pb-1">
            <button className="login-signup-btn" type="submit">
              Register
            </button>
          </div>

          <div className="d-flex gap-2 align-items-center justify-content-center pb-4 ">
            <p className="already-have-an-acco m-0">Already have an account?</p>
            <Link to="/login" className="register-btn">
              Login
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
