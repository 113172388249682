import React from 'react';
import "../assets/Styles/Section4.css";
import img1 from "../assets/Images/section4-1.png";
import img2 from "../assets/Images/section4-2.png";
import img3 from "../assets/Images/section4-3.jpg";
import img4 from "../assets/Images/section4-4.png";


const Section4 = () => {
    return (
        <div className="resume-container">
            <div className="header-section">
                <div className="illustration">
                    <img src={img1} alt="Illustration" />
                </div>
                <div className="text-content">
                    <h1>Use the best resume maker as your guide</h1>
                    <p>
                        Getting that dream job can seem like an impossible task. We're here to change that.
                        Give yourself a real advantage with the best online resume maker.
                    </p>
                    <div className="buttons">
                        <button className="primary-button">Create my resume</button>
                        <button className="secondary-button">Watch video</button>
                    </div>
                </div>
            </div>

            <div className="key-points-section">
                <div className="key-point">
                    <img src={img2} alt="Icon 1" />
                    <h4>Make a resume that wins interviews</h4>
                    <p>
                        Use our resume maker with its advanced creation tools to tell a professional story that engages recruiters, hiring managers and even CEOs.
                    </p>
                </div>
                <div className="key-point">
                    <img src={img2} alt="Icon 2" />
                    <h4>Resume writing made easy</h4>
                    <p>
                        Resume writing has never been this effortless. Pre-generated text, visual designs and more - all already integrated into the resume maker. Just fill in your details.
                    </p>
                </div>
                <div className="key-point">
                    <img src={img2} alt="Icon 3" />
                    <h4>A recruiter-tested CV maker tool</h4>
                    <p>
                        Our resume builder and its pre-generated content are tested by recruiters and IT experts. We help your resume become truly competitive in the hiring process.
                    </p>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default Section4;
