import { useState } from "react";
import { Link } from "react-router-dom";


const LoginForm=()=>{
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        rememberMe: true,
      });
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      };
    
      const validateForm = () => {
        let formErrors = {};
        if (!formData.email) {
          formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          formErrors.email = "Email is invalid";
        }
    
        if (!formData.password) {
          formErrors.password = "Password is required";
        } else if (formData.password.length < 6) {
          formErrors.password = "Password must be at least 6 characters";
        }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
          // Handle form submission
          console.log("Form submitted successfully:", formData);
        }
      };

    return(<>
     <div className="right-login-form">          
        <h3>Login</h3>
        <p>Please enter your credentials to log in.</p>      
                <form onSubmit={handleSubmit}>
                  <div className="form-outline mb-3">
                  {/* <label className="form-label" for="form2Example11">Email Address</label> */}
                    <input type="email" className="form-control"  
                       name="email" 
                       placeholder="Email Address"
                       value={formData?.email}
                       onChange={handleChange}/>

                   {errors.email && <p className="text-danger">{errors.email}</p>}
                  </div>

                  <div className="form-outline mb-3">
                  {/* <label className="form-label" >Password</label> */}
                    <input type="password" name="password" 
                      placeholder="Password"
                      className="form-control"
                      value={formData?.password}
                      onChange={handleChange} />
                    {errors.password && <p className="text-danger">{errors.password}</p>}
                  </div>

                  <div className="d-flex justify-content-between mb-3">  
                   <div className="form-check">
                    <input className="form-check-input" 
                     type="checkbox"
                     name="rememberMe"
                     checked={formData?.rememberMe}
                     onChange={handleChange}/>
                    <label className="form-check-label"> Remember me </label>
                   </div>

                    <div>
                      <a className="text-muted" href="#!">Forgot password?</a>
                    </div>
                  </div> 

                  <div className="d-flex flex-column text-center pt-1 mb-5 pb-1">
                    <button className="login-signup-btn" type="submit">Log in</button>
                  </div>

                  <div className="d-flex gap-2 align-items-center justify-content-center pb-4 ">
                    <p className="already-have-an-acco m-0">Don't have account yet?</p>
                    <Link to="/register" className="register-btn">Register</Link>
                  </div>

                </form>
              </div>
    </>)
};

export default LoginForm;