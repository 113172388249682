// /*!
//   _   _  ___  ____  ___ ________  _   _   _   _ ___   
//  | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
//  | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
//  |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
//  |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

// =========================================================
// * Horizon UI - v1.1.0
// =========================================================

// * Product Page: https://www.horizon-ui.com/
// * Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

// * Designed and Coded by Simmmple

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */

// // Chakra imports
// import { Box, SimpleGrid } from "@chakra-ui/react";
// // import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
// // import DevelopmentTable from "../../../views/admin/dataTables/components/DevelopmentTable"
// // import CheckTable from "../../../views/admin/dataTables/components/CheckTable";
// // import ColumnsTable from "../../../views/admin/dataTables/components/ColumnsTable";
// import ComplexTable from "../../../views/admin/dataTables/components/ComplexTable";
// import {
//   // columnsDataDevelopment,
//   // columnsDataCheck,
//   // columnsDataColumns,
//   columnsDataComplex,
// } from "../../../views/admin/dataTables/variables/columnsData";
// import tableDataComplex from "../../../views/admin/dataTables/variables/tableDataComplex.json";
// import React from "react";

// export default function Settings() {
//   // Chakra Color Mode
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <SimpleGrid
//         mb='20px'
//         columns={{ sm: 1, md: 1 }}
//         spacing={{ base: "20px", xl: "20px" }}>
//         {/* <DevelopmentTable
//           columnsData={columnsDataDevelopment}
//           tableData={tableDataDevelopment}
//         />
//         <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
//         <ColumnsTable
//           columnsData={columnsDataColumns}
//           tableData={tableDataColumns}
//         /> */}

//       </SimpleGrid>
//     </Box>
//   );
// }



// import { templates } from "../Data/templates";
// import { SELECTTEMPLATE } from "../Redux/Actions";
// import DarkEffect from "../Components/DarkEffect";
// import { useNavigate } from 'react-router-dom';
// import { connect } from "react-redux";
// import { Button, Stack } from "@mui/material";


// const mapStateToProps = (state) => ({
//     selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId
// });

// const mapDispatchToProps = (dispatch) => ({
//     setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id))
// });


// const TemplateCard = (props) => {

//     const navigate = useNavigate();

//     const navigateToFill = (id) => {
//         props.setSelectedTemplateId(id);
//         navigate("/template/FillDetails")
//     }

//     return (
//         <>
//             <div className="Home">
//                 <div className="home-templates-cont">
//                     <h3 className="template-header-title">TEMPLATES</h3>
//                     <p className="template-select-text">Select a template to get started</p>

//                     <Stack
//                         sx={{
//                             width: "100%",
//                             display: "grid",
//                             gridTemplateColumns: {
//                                 sm: "1fr 1fr ",
//                                 md: "1fr 1fr ",
//                                 lg: "1fr 1fr 1fr",
//                                 xl: "1fr 1fr 1fr 1fr",
//                             },
//                             gridGap: "30px",
//                         }}>

//                         {templates.map((template) => {
//                             return (
//                                 <div key={template.id} id="template" className="templates-img-cont">
//                                     <img
//                                         className="template-img"
//                                         src={template.template_img}
//                                         alt={template.template_name}
//                                     />

//                                     <DarkEffect />
//                                     <Button
//                                         className="use-template-btn"
//                                         onClick={() => navigateToFill(template.id)}
//                                         size="medium"
//                                         variant="contained">
//                                         Use Template
//                                     </Button>
//                                 </div>
//                             );
//                         })}
//                     </Stack>
//                 </div>
//             </div>
//         </>
//     )
// }


// export default connect(mapStateToProps, mapDispatchToProps)(TemplateCard) 




import { Box, Grid, SimpleGrid, Button } from "@chakra-ui/react";
import React from "react";
import { templates } from "../../../Data/templates";
import { SELECTTEMPLATE } from "../../../Redux/Actions";
import DarkEffect from "../../../Components/DarkEffect";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import "../../../assets/css/DashboardTemplatecard.css"


const mapStateToProps = (state) => ({
  selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id))
});

const Settings = (props) => {

  const navigate = useNavigate();

  const navigateToFill = (id) => {
    props.setSelectedTemplateId(id);
    navigate("/template/FillDetails")
  }
  return (
    <>

      <div className="Home">
        <div className="home-templates-cont">
          <p className="template-select-text">Select a template to get started</p>
          <SimpleGrid columns={4} spacing={10}>

            {templates?.map((template) => {
              return (
                <Box key={template.id} id="template" className="templates-img-cont">
                  <img
                    className="template-img"
                    src={template.template_img}
                    alt={template.template_name}
                  />
                  <DarkEffect />
                  
                  <div className="chkkra-Btn">
                    <Button className="use-template-btn"
                      onClick={() => navigateToFill(template.id)}
                      size="medium"
                      variant="contained">
                      Use Template
                    </Button>
                  </div>
                </Box>
              );
            })}
          </SimpleGrid>
        </div >
      </div >
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

// MuiButtonBase-root.MuiButton-root.use-template-btn {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 5;
//   visibility: hidden;
//   text-transform: capitalize;
//   background-color: #951dc1;
//   color: white;
//   font-weight: 600;
// }
