/* eslint-disable */

import {
  Box,
  Flex,
  HStack,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Select, 
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
// import Card from 'components/card/Card';
import Card from '../../../../components_sec/card/Card';
import Menu from '../../../../components_sec/menu/MainMenu';
import * as React from 'react';
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';

const columnHelper = createColumnHelper();

// const columns = columnsDataCheck;
export default function ComplexTable(props) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1); // Track current page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
         Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('link', {
      id: 'link',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Public Link
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('category', {
      id: 'category',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Category
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('view', {
      id: 'view',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          View
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    // columnHelper.accessor('status', {
    //   id: 'status',
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: '10px', lg: '12px' }}
    //       color="gray.400"
    //     >
    //       STATUS
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center">
    //       <Icon
    //         w="24px"
    //         h="24px"
    //         me="5px"
    //         color={
    //           info.getValue() === 'Approved'
    //             ? 'green.500'
    //             : info.getValue() === 'Disable'
    //             ? 'red.500'
    //             : info.getValue() === 'Error'
    //             ? 'orange.500'
    //             : null
    //         }
    //         as={
    //           info.getValue() === 'Approved'
    //             ? MdCheckCircle
    //             : info.getValue() === 'Disable'
    //             ? MdCancel
    //             : info.getValue() === 'Error'
    //             ? MdOutlineError
    //             : null
    //         }
    //       />
    //       <Text color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue()}
    //       </Text>
    //     </Flex>
    //   ),
    // }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400">
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400">
          Action
        </Text>
      ),
       cell: (info) => (
        <Box display="flex" gap={2}> 
        <Button onClick={() => handleBuildClick(info.row.original)} 
         colorScheme="blue" size="xs">
          Builder
        </Button>

        <Button onClick={() => handleDownloadPDF(info.row.original)} 
         colorScheme="green" size="xs">
          Download PDF
        </Button>
      </Box>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  // Pagination Logic
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedRows = table.getRowModel().rows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  return (
<Card
  flexDirection="column"
  w="100%"
  px="0px"
  overflowX={{ sm: 'scroll', lg: 'hidden' }} // Responsive scrolling for smaller screens
  >
  {/* <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
    <Text
      color={textColor}
      fontSize="22px"
      fontWeight="700"
      lineHeight="100%">
       Resume
    </Text>
    <Menu />
  </Flex> */}

  <Box>
    <Table variant="simple" color="gray.500" mb="24px" mt="12px">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                pe="10px"
                borderColor={borderColor}
                cursor="pointer"
                onClick={header.column.getToggleSortingHandler()}
              >
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize={{ sm: '10px', lg: '12px' }} // Font size varies by screen size
                  color="gray.400"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {{
                    asc: '', // Indicate sort state
                    desc: '', // Placeholder for sorting icons if needed
                  }[header.column.getIsSorted()] ?? null}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      
      <Tbody>
            {paginatedRows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} borderColor="transparent">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
    </Table>
  
    <HStack justify="end" spacing={4} mt={4}>

    <Flex px="25px" mb="8px" alignItems="center">
        <Text mr="10px"  mb="0px" p="0px" color={textColor}>
          Show per row:
        </Text>
        <Select
          value={rowsPerPage}
          onChange={(e) => {
            setRowsPerPage(Number(e.target.value)); // Update rows per page
            // Reset current page to 1 when changing rows per page
            if (currentPage > Math.ceil(paginatedRows.length / Number(e.target.value))) {
              handlePrevPage(); // Go back to the previous page if needed
            }
          }}
          width="100px"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
      </Flex>

          <Button onClick={handlePrevPage} isDisabled={currentPage === 1}>
            Previous
          </Button>
          <Text m="0px" p="0px" >
            Page {currentPage} of {totalPages}
          </Text>
          <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
            Next
          </Button>
        </HStack>
  </Box>
</Card>
  );
}
