import "../../assets/Styles/Footer.css";

const Footer = () => {
    return (
        <>
            <footer className="footer" role="contentinfo">
                <div className="footer-container">
                    <div className="footer-col" role="navigation">
                        <h5 className="footer-logo">resumecv.app</h5>
                        <ul>
                            <li>
                                Use professional field-tested resume templates that follow the exact ‘resume rules’
                                employers look for. Easy to use and done within minutes - try now for free!
                            </li>

                        </ul>
                    </div>
                    <div className="footer-col" role="navigation">
                        <h5>Jobseekers</h5>
                        <ul>
                            <li><a href="#">Create A Resume</a></li>
                            <li><a href="#">Resume Example</a></li>
                            <li><a href="#">Resume Templates</a></li>
                            <li><a href="#">Cover Letter Templates</a></li>
                        </ul>
                    </div>
                    <div className="footer-col" role="navigation">
                        <h5>Career Resources</h5>
                        <ul>
                            <li><a href="#">Resume help</a></li>
                            <li><a href="#">Career</a></li>
                            <li><a href="#">Cover Letter</a></li>
                            <li><a href="#">Blog</a></li>

                        </ul>
                    </div>
                    <div className="footer-col" role="navigation">
                        <h5>Our Company</h5>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Pricing</a></li>
                        </ul>
                    </div>
                    <div className="footer-col" role="navigation">
                        <h5>Support</h5>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Terms Of Service</a></li>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Right of withdrawal</a></li>
                        </ul>
                    </div>

                    <div className="clearfix"></div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 copyright All Right Reserved.</p>
                    {/* <ul className="footer-bottom-links">
                        <li><a href="#">Legal</a></li>
                        <li><a href="#">Credits</a></li>
                        <li><a href="#">Sponsor/Advertise</a></li>
                    </ul> */}
                </div>

            </footer>

        </>
    )
}

export default Footer;