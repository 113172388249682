import "../../assets/Styles/NewNavbar.css";
import NavLogo from "../../assets/Images/NavLogo.png";
import { MdOutlineKeyboardArrowDown, MdCreate } from "react-icons/md";
import { FaFire } from "react-icons/fa";
import { FaHandFist } from "react-icons/fa6";

import { useState,useEffect} from "react";
import { Link } from "react-router-dom";
const NavBar = () => {
  const [hoverResumeServices, setHoverResumeServices] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 100 && currentScrollY < 800 && currentScrollY > lastScrollY) {
      setIsNavbarVisible(false);
    }else{
      setIsNavbarVisible(true)
    }
    // if (currentScrollY < lastScrollY && currentScrollY > 100) {
    //   setIsNavbarVisible(true);
    // }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <nav className={`navbar ${isNavbarVisible ? 'visible' : 'hidden'}`}>
        <div className="navbar-left">
          <a href="/">
            <img
              src={NavLogo}
              // src="https://resume.idea2reality.tech/storage/system/ks47jwcmtBZwKefDiC1Wiq6ydTMid9epJdaDkTii.png"
              alt="Logo"
              className="logo"
            />
          </a>
        </div>
        <div className="navbar-center">
          <ul className="navbar-links">
            <li
              className="dropdown"
              onMouseEnter={() => setHoverResumeServices(true)}
              onMouseLeave={() => setHoverResumeServices(false)}>
              <a className="resume-drop d-flex align-items-center" href="#">
                Resume
                <MdOutlineKeyboardArrowDown size={20} />
              </a>

              <div
                className={`dropdown-content mega-menu ${
                  hoverResumeServices ? "show" : ""
                }`}
                onMouseLeave={() => setHoverResumeServices(false)}>
                <div className="mega-menu-container">
                  <div className="resume-templates">
                    <h5>Resume templates</h5>
                    <ul>
                      <li>
                        <a href="#" className="d-flex align-items-center gap-1">
                          <span>
                            <FaFire size={18} color="rgba(164, 35, 192, 1" />{" "}
                          </span>{" "}
                          Simple
                        </a>
                        <p>
                          Clean, timeless templates with a classic balanced
                          structure. A perfect basic canvas
                        </p>
                      </li>
                      <li>
                        <a
                          href="/MyResume"
                          className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          My Resume
                        </a>
                        <p>
                          A current and stylish feel for forward-thinking
                          candidates in innovative fields
                        </p>
                      </li>
                      <li>
                        <a href="#" className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          Professional
                        </a>
                        <p>
                          Job-winning templates to showcase professionalism,
                          dependability, and expertise
                        </p>
                      </li>
                      <li>
                        <a href="#" className="d-flex align-items-center gap-1">
                          <span>
                            <MdCreate size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Creative
                        </a>
                        <p>
                          A bold, original feel perfect for artistic fields and
                          contemporary companies
                        </p>
                      </li>
                    </ul>
                    <a href="#" className="view-all">
                      View all
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <li className="dropdown"
              onMouseEnter={() => setHoverResumeServices(true)}
              onMouseLeave={() => setHoverResumeServices(false)}>
              <a href="#" className="d-flex align-items-center">
                Cover Letter
                <MdOutlineKeyboardArrowDown size={20} />
              </a>
            </li>
            <li> <a href="#">Blog</a> </li>
            <li> <a href="#">Pricing</a> </li>
          </ul>

          <div className="navbar-right">
            <Link className="my-account" to="/login">
              Login
            </Link>
            <Link className="btn-build-resume text-white" to="/admin/default">
              Dashboard
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};
export default NavBar;
