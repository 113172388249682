import { Route, Routes, useLocation, Navigate} from "react-router";
import React,{useState} from 'react'
import Home from "./Pages/Home"
import CheckSelectedId from './Components/CheckSelectedId';
import FillDetails from "./Pages/FillDetails"
import About from "./Pages/About"
import MyResume from "./Pages/MyResumes"
import Login from './Pages/Login';
import Register from './Pages/Register';
import Footer from './Components/Footer/Footer';
import NewNavBar from "./Components/NewNavbar/NavBar";
// import Dashboard from "./Pages/Dashboard/Dashboard";
import AdminLayout from './layouts/admin';
// import RTLLayout from './layouts/rtl';
import initialTheme from './theme/theme';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import TemplateCard from "./Pages/TemplateCard";
// import MembershipTransaction from "./views/admin/MembershipTransaction";


const RouterNavigate=()=>{
  const location = useLocation();
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const showFooterNavbar = location?.pathname !== '/login' && 
                     location?.pathname !== '/register' && 
                     location?.pathname !== "/admin/default" &&
                     location?.pathname !== "/admin/profile" &&
                     location?.pathname !== "/admin/myresume" &&
                     location?.pathname !== "/admin/templates" &&
                     location?.pathname !== "/admin/pricingplan" &&
                    location?.pathname !== "/admin/transactions"
    return(<>
     {showFooterNavbar &&<NewNavBar /> }
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/template/FillDetails" element={<CheckSelectedId> <FillDetails/> </CheckSelectedId> } />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/MyResume" element={<MyResume/>} />
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/register" element={<Register/>}/>
        <Route exact path="/template/FillDetails"
          element={
            <CheckSelectedId>
              < FillDetails />
            </CheckSelectedId>
          } />
        <Route exact path="/templatecard" element={<TemplateCard />} />
      </Routes>
      
      <ChakraProvider theme={currentTheme}>
       <Routes>

        <Route path="admin/*" element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme}/> }/>
          
        <Route path="/" element={<Navigate to="/" replace />} />
        
        </Routes> 
      </ChakraProvider>

      {/* <Route path="rtl/*" element={<RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />}/> */}

      {showFooterNavbar && <Footer />} 
    </>)
};

export default RouterNavigate;