import React from "react";
import "../assets/Styles/TemplateEducationComponent.css";


const TemplateEducationComp = (props) => {
    return (
        <>
            {props.education && props.education.length > 0 ? (
                props.education.map((education, index) => (
                    <h3 key={index} className="template-education-details">
                        {education.degree} from {education.domain}
                        <span className="template-education-university">
                            {education.university}
                        </span>
                        <span className="education-start-end">
                            ({education.startYear}-{education.endYear})
                        </span>
                    </h3>
                ))
            ) : (
                ""
            )}
        </>
    )
}

export default TemplateEducationComp; 