import "../assets/Styles/Section8.css"
import React, { useState } from 'react';

const Section8 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <>
            <div className="faq-container">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-item" onClick={() => toggleFAQ(0)}>
                    <div className="faq-question">
                        How can I use resumecv.app for free?
                        <span>{activeIndex === 0 ? '-' : '+'}</span>
                    </div>
                    {activeIndex === 0 && (
                        <div className="faq-answer">
                            <p>Answer for how to use resumecv.app for free...</p>
                        </div>
                    )}
                </div>

                <div className="faq-item" onClick={() => toggleFAQ(1)}>
                    <div className="faq-question">
                        How can I customize my resume?
                        <span>{activeIndex === 1 ? '-' : '+'}</span>
                    </div>
                    {activeIndex === 1 && (
                        <div className="faq-answer">
                            <p>Answer for how to customize resume...</p>
                        </div>
                    )}
                </div>

                <div className="faq-item" onClick={() => toggleFAQ(2)}>
                    <div className="faq-question">
                        Can I download my resume to Word or PDF?
                        <span>{activeIndex === 2 ? '-' : '+'}</span>
                    </div>
                    {activeIndex === 2 && (
                        <div className="faq-answer">
                            <p>Answer for downloading resume...</p>
                        </div>
                    )}
                </div>

                <div className="faq-item" onClick={() => toggleFAQ(3)}>
                    <div className="faq-question">
                        How do I cancel, downgrade or delete my account?
                        <span>{activeIndex === 3 ? '-' : '+'}</span>
                    </div>
                    {activeIndex === 3 && (
                        <div className="faq-answer">
                            <p>Answer for cancelling or deleting account...</p>
                        </div>
                    )}
                </div>

                <div className="faq-footer">
                    <p>
                        Didn't find what you're looking for? — <a href="#">Use our FAQ</a>
                    </p>
                </div>
            </div>
        </>
    )
}
export default Section8;