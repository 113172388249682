import { Link } from "react-router-dom";
import RegisterForm from "../NewComponents/Register/Register";
import Navlogo from "../assets/Images/NavLogo.png"


const Register = () => {
   return (<>
      <div className="login-section">
         <div className="container">
            <div >
               <Link to="/">
                  <img className="mt-4" style={{ height: 20 }}
                     src={Navlogo}
                     //  src="https://resume.idea2reality.tech/storage/system/ks47jwcmtBZwKefDiC1Wiq6ydTMid9epJdaDkTii.png" 
                     alt="logo" />
               </Link>
            </div>

            <div className="row align-items-center" style={{ height: "100vh" }}>
               <div className="col-12 col-lg-6 d-none d-lg-block">
                  <div className="login-left-section mt-4">
                     <h3>Start your journey with us.</h3>
                     <p>Discover the world’s best community of resume creators and cover pages..</p>
                  </div>
               </div>

               <div className="col-12 col-lg-6 ">
                  <RegisterForm />
               </div>
            </div>
         </div>
      </div>
   </>)
};
export default Register;