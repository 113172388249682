import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import "../../../../assets/css/EditProfileForm.css";

function EditProfileModal(props) {
  const { isOpen, onOpen, onClose } = props;

  const [formData, setFormData] = useState({
    profile: "",
    username: "",
    registrationDate: "",
    name: "",
    websiteUrl: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    country: "",
    state: "",
    city: "",
    description: "",
    industry: "",
    minimumSalary: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log("Form submitted:", formData);
  };

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="xl">

        <ModalOverlay />
        <ModalContent p={5}>
          <ModalHeader p={2}>Edit Your Profile</ModalHeader>
          <ModalCloseButton />

          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="d-flex gap-3">
                <div className="form-group w-100">
                  <label htmlFor="profile">Profile:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="profile"
                    value={formData.profile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group w-100">
                  <label className="radio-btn-label">Username:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex  mt-3 gap-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">Registration Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    name="registrationDate"
                    value={formData.registrationDate}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group w-100">
                  <label className="radio-btn-label">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex gap-3 mt-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">Website URL:</label>
                  <input
                    type="url"
                    className="form-control"
                    name="websiteUrl"
                    value={formData.websiteUrl}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group w-100">
                  <label className="radio-btn-label">Email Address:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex gap-3 mt-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">Phone Number:</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group w-100">
                  <label className="radio-btn-label">Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex gap-3 mt-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">Confirm Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group w-100">
                  <label className="radio-btn-label">Country:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex gap-3 mt-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">State:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group w-100">
                  <label className="radio-btn-label">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <label className="radio-btn-label">Description:</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="d-flex gap-3 mt-3">
                <div className="form-group w-100">
                  <label className="radio-btn-label">Industry:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group w-100">
                  <label className="radio-btn-label">Minimum Salary:</label>
                  <input
                    type="number"
                    className="form-control"
                    name="minimumSalary"
                    value={formData.minimumSalary}
                    onChange={handleChange}
                  />
                </div>
              </div>
                                                  
              <div className="form-group w-100 mt-3">
                <label className="radio-btn-label">Seniority:</label>
                <div className="d-flex gap-3">
                  {["Entry",
                    "Junior",
                    "Mid",
                    "Senior",
                    "Lead",
                  ]?.map((option, index) => (
                    <div className="d-flex align-items-center gap-1" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id={`radioOption${index + 1}`}
                        value={`option${index + 1}`}
                      />
                      <label className="form-check-label radio-btn-label mt-1">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
                                               
              <div className="form-group w-100 mt-3">
                <label className="radio-btn-label">Employment Type:</label>
                <div className="d-flex gap-3 mt-1">
                  {[ "Full-Time ",
                    "Part-Time",
                    "Project/Freelance",
                    "Internship",
                  ]?.map((option, index) => (
                    <div className="d-flex gap-1" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="exampleRadios"
                        id={`radioOption${index + 1}`}
                        value={`option${index + 1}`}
                      />
                      <label className="form-check-label radio-btn-label">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
                                   
              <div className="form-group w-100 mt-3">
                <label className="radio-btn-label">Salary Rate:</label>
                <div className="d-flex gap-3 mt-1">
                  {[
                    "Hourly",
                    "Monthly",
                    "Yearly",
                  ]?.map((option, index) => (
                    <div className="d-flex gap-1" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id={`radioOption${index + 1}`}
                        value={`option${index + 1}`}
                      />
                      <label
                        className="form-check-label radio-btn-label ">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

            </form>
          </div>

          <ModalFooter color="white" backgroundColor="white">
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditProfileModal;
