import React from 'react';
import "../assets/Styles/Section3.css"

const Section3 = () => {
    const features = [
        {
            icon: '🖱️', // Replace with actual SVG or image
            title: 'Easy online resume builder',
            description: 'Create an awesome resume in minutes, without leaving your web browser.',
        },
        {
            icon: '📝',
            title: 'Automatic spell-checker',
            description: 'Our built-in spell-checker takes care of the grammar for you. Create a resume with zero typos or errors.',
        },
        {
            icon: '🔒',
            title: 'Your data is safe',
            description: 'Your data is kept private and protected by strong 256-bit encryption.',
        },
        {
            icon: '🧠',
            title: 'Automatic summary generator',
            description: 'Create a powerful resume profile or cover letter in one click. Try for free!',
        },
        {
            icon: '📄',
            title: 'Approved templates',
            description: 'Professionally-designed resume templates and examples. Just edit and download in 5 minutes.',
        },
        {
            icon: '🤖',
            title: 'AI pre-written phrases',
            description: 'Use the power of AI and data analysis, choose pre-generated effective phrases and keywords.',
        },
        {
            icon: '🔧',
            title: 'Optimized resumes',
            description: 'Formats and designs are optimized for resume-filtering algorithms. Ensure humans see your application!',
        },
        {
            icon: '📂',
            title: 'Multi-format resume options',
            description: 'Save your perfect resume in any common format, including Microsoft Word and PDF in a single click.',
        },
        {
            icon: '✉️',
            title: 'Cover letters',
            description: 'Our cover letter builder works with the same ease and use of elegant templates as the resume creator.',
        },
    ];

    return (
        <div className="features-container">
            <h1>Features designed to help you win your dream job</h1>
            <div className="features-grid">
                {features?.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <span className="icon">{feature.icon}</span>
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Section3;
