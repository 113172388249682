import "../assets/Styles/Section1.css";
import profileImg from "../assets/Images/avatar.jpg"
import forbeslogo from "../assets/Images/forbes-logo.png"
import entrepreneurlogo from "../assets/Images/entrepreneur-logo.png"
import themuselogo from "../assets/Images/themuse-logo.png"
import lifehacklogo from "../assets/Images/lifehack-logo.png"
import inclogo from "../assets/Images/inc-logo.png"
import { useNavigate } from "react-router";

const Section1 = () => {
    const navigate = useNavigate();

    const createResume = () => {
        navigate('/templatecard')
    }

    return (
        <div className="resume-builder">
            <header className="header">
                <h1>Only 2% of resumes make it past the <br /> first round. Be in the top 2%</h1>
                <p>Use professional field-tested resume templates that follow the exact <br />‘resume rules’ employers look for. Easy to use and done within <br /> minutes - try now for free!</p>
                <button className="cta-button" onClick={createResume}>Create my resume</button>
                {/* <span>26,433 resumes created today</span> */}
            </header>

            <div className="resume-preview">
                <div className="resume-card">
                    <div className="resume-info ">
                        <div className="d-flex justify-content-center">
                            <img src={profileImg} alt="Profile" className="profile-pic" />
                        </div>
                        <h2>Sophie Walton</h2>
                        <p>Customer Service Representative</p>
                        <address>
                            <p>32600 42nd Ave SW<br />Seattle, WA 98116<br />United States</p>
                            <p>(206) 742-5187</p>
                            <p>hw12@yahoo.com</p>
                        </address>
                    </div>
                    <div className="resume-details">
                        <h3>Profile</h3>
                        <p>Dedicated Customer Service Representative...</p>
                        <h3>Employment History</h3>
                        <p>Branch Customer Service Representative...</p>
                    </div>
                </div>
            </div>

            <div className="trusted-logos d-flex justify-content-center align-items-center">
                <div> <img src={forbeslogo} alt="Forbes" /> </div>

                <div> <img src={themuselogo} alt="The Muse" /> </div>

                <div> <img src={entrepreneurlogo} alt="Entrepreneur" /> </div>

                <div> <img src={lifehacklogo} alt="Lifehack" /> </div>

                <div> <img src={inclogo} alt="Inc." /> </div>
            </div>
        </div>
    )
}

export default Section1;