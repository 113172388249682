
// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  // columnsDataDevelopment,
  // columnsDataCheck,
  // columnsDataColumns,
  columnsDataComplex,
} from "../dataTables/variables/columnsData";
import tableDataTransaction from "../dataTables/variables/tableDataTransaction.json";
import React from "react";
import MembershipTrasactionTable from "./components/MembershipTransactionTable";

export default function Settings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/*<DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />*/}
        <MembershipTrasactionTable
         columnsData={columnsDataComplex}
         tableData={  tableDataTransaction}
        />
      </SimpleGrid>
    </Box>
  );
}
