/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import Usa from "assets/img/dashboards/usa.png";
import Usa from "../../../assets/img/dashboards/usa.png"
// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniCalendar from "../../../components_sec/calendar/MiniCalendar"
import MiniStatistics from "../../../components_sec/card/MiniStatistics";
import IconBox from "../../../components_sec/icons/IconBox";
import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

// import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import ComplexTable from "../../../views/admin/dataTables/components/ComplexTable";

import {columnsDataComplex} from "../../../views/admin/dataTables/variables/columnsData";

// import CheckTable from "views/admin/default/components/CheckTable";
// import CheckTable from "./components/CheckTable";
// import ComplexTable from "components/ComplexTable";
// import DailyTraffic from "components/DailyTraffic";
// import PieCard from "components/PieCard";
// import Tasks from "components/Tasks";
// import TotalSpent from "components/TotalSpent";
// import WeeklyRevenue from "components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "variables/columnsData";
// import tableDataCheck from "variables/tableDataCheck.json";
// import tableDataComplex from "variables/tableDataComplex.json";
import tableDataComplex from "../../../views/admin/dataTables/variables/tableDataComplex.json";
import { FaEye } from "react-icons/fa";
export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base:"130px", md: "80px", xl: "80px" }} height="400px">
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap='20px'
        mb='20px'>

        <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}/>  
          }
          name='Total Resume'
          value='4000'
        />

        <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={FaEye} color={brandColor} />}/>
          }
          name='Total Views Resume'
          value='2349'
        />
        
        <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}/>
          }
          name='List Of My Resume'
          value='2935'
        />

      </SimpleGrid>
      
      <ComplexTable
        columnsData={columnsDataComplex}
        tableData={tableDataComplex}
      />
      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
